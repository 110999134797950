import React from 'react';
import Seo from '../components/seo.js';
import Nav from '../components/nav.js';
import Footer from '../components/footer.js';
import '../styles/global.scss';


const Layout = ({ children }) => {
    return (
        <div className='site-wrapper'>
            <Seo />
            <Nav />
            { children }
            <Footer />
        </div>
    )

}

export default Layout