import React from 'react';
import Wordmark from '../assets/logos/wordmark.svg';
import '../styles/nav.scss';

const Nav = ({ children }) => {
    return (
        <nav className="nav">
            <Wordmark />
        </nav>
    )

}

export default Nav