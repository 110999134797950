import React, {useState} from 'react';
import '../styles/contact.scss';
import Avends from "../assets/video/avends.mp4"


const Contact = (props) => {

    const [formSubmitted, setFormSubmitted] = useState(false)
    const [formState, setFormState] = useState({name:"", email:"", company:"", website:"", message:""})

    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&")
    }

    const submit = (e) => {
        e.preventDefault();
        const form = e.target;
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({
              "form-name": form.getAttribute("name"),
              ...formState
            })
        }).then(() => {
            setFormSubmitted(true)
            form.reset();
            window.dataLayer?.push({
                event: 'form_interaction',
                eventAction: 'submit'
            })
        }).catch(error => console.error(error))
    }

    const handleChange = (e) => {
        setFormState(prevState => {
            let form = prevState;
            form[e.target.getAttribute("name")] = e.target.value;
            return form
        })
    }

    return (
        <section className="ff contact" id="contact">
            <h2 className="ff ff-section-header">Get in touch</h2>
            <p className="ff subheader">If you have a project in mind, or you just want to find out more about how we can help, please fill out the form below and we'll get in touch.</p>
            <form 
                name="contact"
                method="POST"
                action="/"
                className="ff ff-form" 
                onSubmit={submit}   
                onChange={handleChange}
                data-netlify-honeypot="bot-field"
                netlify="true">
                <input type="hidden" value="contact" name="bot-field" />
                <div className='input small'>
                    <label htmlFor="name">Name</label><input id="name" type="text" name="name" required/>
                </div>
                <div className='input small'>
                    <label htmlFor="company">Company</label><input id="company" type="text" name="company" />
                </div>
                <div className='input small'>
                    <label htmlFor="email">Email</label><input id="email" type="email" name="email" required/>
                </div>
                <div className='input small'>
                    <label htmlFor="website">Website</label><input id="website" type="text" name="website" />
                </div>
                <div className='input large'>
                    <label htmlFor="message">Message</label><textarea id="message" name="message" rows="6"></textarea>
                </div>
                <button className="ff ff-primary" type="submit">{formSubmitted ? 'Speak Soon :)' : 'Send'}</button>
            </form>
            <div className="video">
                <video muted autoPlay loop preload="none">
                    <source src={Avends} type="video/mp4" />
                </video>
            </div>


        </section>
    )
}

export default Contact